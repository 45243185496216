import { MenuEntry } from '@pancakeswap-libs/uikit'

const config: MenuEntry[] = [
  {
    label: 'Home',
    icon: 'HomeIcon',
    href: '/', //  'https://www.goosedefi.com/'
  },
  {
    label: 'Trade',
    icon: 'TradeUpdatedIcon',
    initialOpenState: true,
    items: [
      {
        label: 'Exchange',
        href: '#/swap'
      },
      {
        label: 'Liquidity',
        href: '#/pool'
      }
    ]


  },
  {
    label: 'Farms',
    icon: 'FarmIcon',
    href: 'https://rimswap.app/farms', // 'https://www.goosedefi.com/farms'
  },
  {
    label: 'Pools',
    icon: 'PoolIcon',
    href: 'https://rimswap.app/pools', // 'https://www.goosedefi.com/farms'
  },
  {
    label: 'NFT',
    icon: 'NftIcon',
    href: 'https://www.oasisgorillas.com/',
  },

  {
    label: 'Bridges',
    icon: 'PoolIcon',
    items: [
      {
        label: 'EvoDefi',
        href: 'https://bridge.evodefi.com/'
      },
      {
        label: 'WormHole',
        href: 'https://portalbridge.com/#/transfer'
      }
    ]
  },
  // {
  //   label: 'Lottery',
  //   icon: 'TicketIcon',
  //   href: 'https://www.goosedefi.com/lottery'
  // },
  // {
  //   label: 'Info',
  //   icon: 'InfoIcon',
  //   items: [
  //     {
  //       label: 'PancakeSwap',
  //       href: 'https://pancakeswap.info/token/0xF952Fc3ca7325Cc27D15885d37117676d25BfdA6',
  //     },
  //     {
  //       label: 'CoinGecko',
  //       href: 'https://www.coingecko.com/en/coins/goose-finance',
  //     },
  //     {
  //       label: 'CoinMarketCap',
  //       href: 'https://coinmarketcap.com/currencies/goose-finance/',
  //     },
  //     {
  //       label: 'AstroTools',
  //       href: 'https://app.astrotools.io/pancake-pair-explorer/0x19e7cbecdd23a16dfa5573df54d98f7caae03019',
  //     },
  //   ]
  // },


  {
    label: 'Socials',
    icon: 'MoreIcon',
    items: [
      {
        label: "Twitter",
        href: "https://twitter.com/RIMSwap",
      },
      {
        label: "Telegram",
        href: "https://t.me/Rimswap_chat",
      },
      {
        label: "Telegram",
        href: "https://discord.gg/uXWSuCdQcr",
      },
    ],
  },


  {
    label: 'More',
    icon: 'MoreIcon',
    items: [
      {
        label: "Contact",
        href: "https://t.me/Rimswap_chat/",
      },
      {
        label: "Docs",
        href: "https://bit.ly/3w16iGb",
      },
      {
        label: "Tokenomics",
        href: "https://bit.ly/3J62K9o",
      },
      {
        label: "Roadmap",
        href: "https://bit.ly/3w3GTf7",
      },
    ],
  },

  // {
  //   label: 'Partnerships/IFO',
  //   icon: 'GooseIcon',
  //   href: 'https://docs.google.com/forms/d/e/1FAIpQLSe7ycrw8Dq4C5Vjc9WNlRtTxEhFDB1Ny6jlAByZ2Y6qBo7SKg/viewform?usp=sf_link',
  // },
  // {
  //   label: 'Audit by Hacken',
  //   icon: 'AuditIcon',
  //   href: 'https://www.goosedefi.com/files/hackenAudit.pdf',
  // },
  // {
  //   label: 'Audit by CertiK',
  //   icon: 'AuditIcon',
  //   href: 'https://certik.org/projects/goose-finance',
  // },
]

export default config
