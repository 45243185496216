import React from 'react'
import useI18n from 'hooks/useI18n'

export interface TranslatedTextProps {
  translationId: number
  children: string
  color?:string
}

const TranslatedText = ({ translationId, children, color }: TranslatedTextProps) => {
  const TranslateString = useI18n()
  return <div style={{color:"#000"}}>{TranslateString(translationId, children)}</div>
}

export default TranslatedText
