import { useEffect, useState } from 'react'
import BigNumber from 'bignumber.js'
import { useMulticallContract } from './useContract'
import ERC20_INTERFACE from '../constants/abis/erc20'
import priceContracts from '../constants/eggPriceContracts'

type ApiResponse = {
  prices: {
    [key: string]: string
  }
  update_at: string
}

/**
 * Due to Cors the api was forked and a proxy was created
 * @see https://github.com/panrimswap/gatsby-panrim-api/commit/e811b67a43ccc41edd4a0fa1ee704b2f510aa0ba
 */
const api = 'https://api.panrimswap.com/api/v1/price'

const useGetPriceData = () => {
  const [data, setData] = useState<number>(0)

  const multicallContract = useMulticallContract();

  useEffect(() => {
    const fetchData = async () => {
      try {
        if(multicallContract){
          // rim: string; wRose: string; rimRoseLP: string; usdt: string; roseBUSDLP: string
          const {rim, wRose, rimRoseLP,usdt,roseBUSDLP} = priceContracts;
          const calls = [
            [rim, ERC20_INTERFACE.encodeFunctionData("balanceOf", [rimRoseLP])],
            [wRose, ERC20_INTERFACE.encodeFunctionData("balanceOf", [rimRoseLP])],


            [wRose, ERC20_INTERFACE.encodeFunctionData("balanceOf", [roseBUSDLP])],
            [usdt, ERC20_INTERFACE.encodeFunctionData("balanceOf", [roseBUSDLP])],

          ];

          const [resultsBlockNumber, result] = await multicallContract.aggregate(calls);
          const [rimAmountInLP, wRoseAmountInRIMLP,
          
            wRoseAmountInUSDTLP,
            usdtAmountInRoseLP
          ] = result.map(r=>ERC20_INTERFACE.decodeFunctionResult("balanceOf", r));
          const _rimAmountInLP = new BigNumber(rimAmountInLP);
          const _wRoseAmountInRIMLP = new BigNumber(wRoseAmountInRIMLP);
          const rimPriceInRose = _wRoseAmountInRIMLP.div(1e18).div(_rimAmountInLP.div(1e18))



          const _wRoseAmountInUSDTLP = new BigNumber(wRoseAmountInUSDTLP);
          const _usdtAmountInRoseLP = new BigNumber(usdtAmountInRoseLP);
          const rosePrice = (_usdtAmountInRoseLP.div(1e6) ).div(_wRoseAmountInUSDTLP.div(1e18)).toNumber();

          console.log("rimPriceInRose",rimPriceInRose.toString())

          setData(rimPriceInRose.times(rosePrice).toNumber())
        }
      } catch (error) {
        console.error('Unable to fetch price data:', error)
      }
    }

    fetchData()
  }, [multicallContract])

  return data
}

export default useGetPriceData
